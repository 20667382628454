import axios from 'axios'
// axios.defaults.withCredentials = true;
const http = options => {
    return new Promise((resolve, reject) => {
        let defaultOptions = {};
        let newOptions = {
            ...defaultOptions,
            ...options
        };
        newOptions.headers = {
            'content-Type': 'application/json;charset=UTF-8',
            ...newOptions.headers
        };
        axios({
            method: newOptions.method,
            url: newOptions.url,
            data: newOptions.data,
            headers: newOptions.headers,
            timeout: 5000
        }).then(res => {
            if (res.status == 200) {
                resolve(res.data);
            } else {
                reject(res);
            }
        }).catch(err => {
            reject(err);
        })
    })
};
export default http