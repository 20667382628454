import request from './request';
import store from '../store';
let apiUrl = store.state.apiUrl;
let room = {
  isRoomExsit: async (roomId) => {
    return await request({
      method: 'get',
      url: apiUrl + '/room/isExist/?roomId=' + roomId
    })
  },
  createRoom: async()=>{
    return await request({
      method: 'post',
      url: apiUrl + '/room/create'
    })
  }

}
export default room;