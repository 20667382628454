<template>
  <div>
    <div class="inputContainer">
      <HanInputVue v-model="nickname" placeholder="昵称" />
    </div>

    <br />
    <HanButtonVue color="blue" @click="confirmCreateRoom">确定</HanButtonVue>
    <br /><br />
    <HanButtonVue @click="back">返回</HanButtonVue>
  </div>
</template>

<style scoped>
.inputContainer {
  margin: 20px 0;
}
</style>

<script>
import HanInputVue from "../Common/HanInput.vue";
import HanButtonVue from "../Common/HanButton.vue";
import { onMounted, ref, useStore, defineEmits } from "vue";
import roomApi from "../../api/room";
import { useRouter } from "vue-router";
export default {
  name: "CreateRoomComponent",
  setup(props, ctx) {
    const router = useRouter();
    // const emit = defineEmits(['back'])
    const nickname = ref(null);
    const confirmCreateRoom = async () => {
      console.log("confirmCreateRoom");
      localStorage.setItem("nickname", nickname.value);
      let res = await roomApi.createRoom();
      router.push({
        name: 'room',
        params: {
          roomId: res.data.roomId
        },
      });
    };

    const back = () => {
      ctx.emit("back");
    };

    return {
      nickname,
      confirmCreateRoom,
      back,
    };
  },
  components: {
    HanInputVue,
    HanButtonVue,
  },
};
</script>
