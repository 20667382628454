import { createStore } from 'vuex'

export default createStore({
  state: {
    apiUrl: 'https://pmfxapi.mengyuhan.me:3000',
    nickname: null
  },
  getters: {
  },
  mutations: {
    saveNickname (state, nickname) {
      state.nickname = nickname
    }
  },
  actions: {
  },
  modules: {
  }
})
