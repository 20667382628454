import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const roomApi = require('@/api/room.js')
const utils = require('@/utils')

import '@/assets/main.build.css'

const app = createApp(App)
app.config.globalProperties.$room = roomApi
app.config.globalProperties.$utils = utils
app.use(store).use(router).mount('#app')
