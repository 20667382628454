
import { ref, onMounted, Ref } from 'vue'
import HanButton from '../components/Common/HanButton.vue'
import CreateRoomComponent from '../components/Home/CreateRoom.vue'
import EnterRoomComponent from '../components/Home/EnterRoom.vue'

export default {
  name: 'HelloWorld',
  setup () {
    const version: Ref = ref(null)
    const action: Ref = ref(null)

    const createRoom = () => {
      action.value = 'create'
    }
    const enterRoom = () => {
      action.value = 'enter'
    }
    const back = () =>{
      action.value = null
    }

    onMounted(() => {
      if (version.value !== null) version.value.innerText = process.env.BUILD_VERSION
    })
    return {
      createRoom,
      enterRoom,
      version,
      action,
      back
    }
  },
  components: { HanButton, CreateRoomComponent, EnterRoomComponent }
}
