<template>
  <button
    :color="color"
    class="han-btn"
    :disabled="disabled"
    :loading="loading"
  >
    <slot></slot>
  </button>
</template>

<style>
.han-btn {
  cursor: pointer;
  background-color: #303134;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 16px;
  border-radius: 5px;
  user-select: none;
}

.han-btn[color="blue"] {
  background-color: #1392de;
}
</style>

<script>
export default {
  name: "HanButton",
  install: function (Vue) {
    Vue.components("HanButton", this);
  },
  props: {
    color: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
