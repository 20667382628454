<template>
  <div>
    <div class="inputContainer">
      <HanInputVue v-model="roomId" placeholder="房间ID" />
      <HanInputVue v-model="nickname" placeholder="昵称" />
    </div>

    <br />
    <HanButtonVue color="blue" @click="enterRoom">进入</HanButtonVue>
    <br /><br />
    <HanButtonVue @click="back">返回</HanButtonVue>
  </div>
</template>

<style scoped>
.inputContainer {
  margin: 20px 0;
}
</style>

<script>
import HanInputVue from "../Common/HanInput.vue";
import HanButtonVue from "../Common/HanButton.vue";
import { onMounted, ref, useStore, defineEmits } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "CreateRoomComponent",
  setup(props, ctx) {
    // const emit = defineEmits(['back'])
    const router = useRouter();
    const roomId = ref(null);
    const nickname = ref(null);

    const enterRoom = () => {
      localStorage.setItem("nickname", nickname.value);
      router.push({
        path: "/r/" + roomId.value,
      });
    };

    const back = () => {
      ctx.emit("back");
    };

    return {
      roomId,
      nickname,
      enterRoom,
      back,
    };
  },
  components: {
    HanInputVue,
    HanButtonVue,
  },
};
</script>
