<template>
  <label v-if="label">{{ label }}</label>
  <input
    class="han-input"
    type="text"
    v-if="type === 'input'"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :placeholder="placeholder"
  />
</template>

<style>
.han-input {
  background-color: transparent;
  outline: none;
  border: 2px solid #606060;
  padding: 12px 14px;
  color: #fff;
  border-radius: 4px;
}
</style>

<script>
export default {
  name: "HanInput",
  install: function (Vue) {
    Vue.component("HanInput", this);
  },
  setup(props, context) {
    const updateValue = (event) => {
      context.emit("update:modelValue", event.target.value);
    };
    return { updateValue };
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
    type: {
      type: String,
      default: "input",
    },
    rows: {
      type: Number,
      default: 5,
    },
    color: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>
