
import { ref, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import io from "socket.io-client";
import { Device } from "mediasoup-client";
import HanButtonVue from "../components/Common/HanButton.vue";
export default defineComponent({
  name: "RoomView",
  setup(props, ctx) {
    // const io = require('socket.io-client');
    const isHosting = ref(true);

    const socket = io('https://pmfxapi.mengyuhan.me:3000/mediasoup')
    // const socket = io("http://localhost:3000/mediasoup");
    const route = useRoute();
    const { params } = route;
    const roomId = params.roomId;
    const nickname = localStorage.getItem("nickname");
    const users = ref([]) as any;

    socket.on("connection-success", ({ socketId }) => {
      console.log({ socketId });
    });

    const produceParams: any = {
      encoding: [
        {
          rid: "r0",
          maxBitrate: 10000000,
          maxFramerate: 60,
          adaptivePtime: true,
          dtx: true,
          scalabilityMode: "S1T3",
        },
        {
          rid: "r1",
          maxBitrate: 10000000,
          maxFramerate: 60,
          adaptivePtime: true,
          dtx: true,
          scalabilityMode: "S1T3",
        },
        {
          rid: "r2",
          maxBitrate: 10000000,
          maxFramerate: 60,
          adaptivePtime: true,
          dtx: true,
          scalabilityMode: "S1T3",
        },
      ],
      codecOptions: {
        videoGoogleStartBitrate: 1000000,
      },
    };

    let audioParams: any;
    let videoParams: any = { produceParams };

    let device: Device;
    let rtpCapabilities: any;
    let audioProducer;
    let videoProducer;
    let consumer;

    let producerTransport: any;
    let consumerTransports: any[] = [];
    let isProducer = false;
    let hasProducer = ref(false);
    // let isHosting = false;

    const goConnect = async (producerOrConsumer: boolean) => {
      isProducer = producerOrConsumer;
      await getLocalStream();
      // if (isProducer) {
      //   await getLocalStream()
      // } else {
      //   device === undefined ? getRtpCapabilities() : createTransport()
      // }
    };

    const joinRoom = (): void => {
      socket.emit("join-room", { roomId, nickname }, (data: any) => {
        rtpCapabilities = data.rtpCapabilities;
        console.log("join room", data);
        isHosting.value = data.isHosting;
        users.value = data.allPeers;
        createDevice();
      });
    };

    const goLive = () => {
      isHosting.value = true;
      hasProducer.value = true;
      getLocalStream();
    };
    const goConsume = () => {
      goConnect(false);
    };

    // const createTransport = () => {
    //   isProducer ? createSendTransport() : createRecvTransport()
    // }

    const getLocalStream = async () => {
      try {
        let captureStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
          /* {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100
          } */
        });

        getStreamSuccess(captureStream);
      } catch (err) {
        console.error(`Error: ${err}`);
      }
    };

    const getStreamSuccess = async (stream: MediaStream) => {
      const video: any = document.getElementById("video");
      video.srcObject = stream;

      audioParams = { track: stream.getAudioTracks()[0], ...audioParams };
      videoParams = { track: stream.getVideoTracks()[0], ...videoParams };

      if (device === undefined) {
        await createDevice();
      }
      createSendTransport();
      // joinRoom()
      // const video: any = document.getElementById('video')
      // video.srcObject = stream
      // const track = stream.getVideoTracks()[0]
      // // const audioTrack = stream.getAudioTracks()[0]
      // produceParams = {
      //   track,
      //   ...produceParams
      // }
      // console.log('produceParams', produceParams)
      // device === undefined ? getRtpCapabilities() : createTransport()
      // video.play()
    };

    const getRtpCapabilities = () => {
      socket.emit("create-room", (data: any) => {
        rtpCapabilities = data.rtpCapabilities;
        console.log("rtpCapabilities", rtpCapabilities);
        createDevice();
      });
    };

    socket.on("new-producer", ({ producerId }: any) => {
      signalNewConsumerTransport(producerId);
    });

    socket.on("peer-joined", ({ socketId, peerDetails, allPeers }: any) => {
      console.log("peer-joined", socketId, peerDetails, allPeers);
      users.value = allPeers;
    });

    socket.on("user-list", (data: any) => {
      console.log("user-list", data);
      users.value = data.allPeers;
    });

    const getProducers = () => {
      socket.emit("getProducers", (producerIds: []) => {
        console.log("producerIds", producerIds);
        producerIds.forEach((producerId) =>
          signalNewConsumerTransport(producerId)
        );
      });
    };

    const createDevice = async () => {
      try {
        device = new Device();
        await device.load({
          routerRtpCapabilities: rtpCapabilities,
        });
        console.log("createDevice");

        console.log("Return RTP Capabilities", rtpCapabilities);

        console.log("Device RTP Capabilities", device.rtpCapabilities);

        // if (!isHost) {
        //   console.log('get producers')

        //   getProducers()
        // }
        // createSendTransport()
        getProducers();
      } catch (err) {
        console.error(err);
      }
    };

    const createSendTransport = () => {
      socket.emit(
        "createWebRtcTransport",
        { sender: true },
        ({ params }: any) => {
          if (params.error) {
            console.log(params.error);
            return;
          }
          console.log(params);
          producerTransport = device.createSendTransport(params);
          producerTransport.on(
            "connect",
            async ({ dtlsParameters }: any, callback: any, errback: any) => {
              try {
                await socket.emit("transport-connect", {
                  // transportId: producerTransport.id,
                  dtlsParameters,
                });

                callback();
              } catch (error) {
                errback(error);
              }
            }
          );

          producerTransport.on(
            "produce",
            async (parameters: any, callback: any, errback: any) => {
              console.log(parameters);
              try {
                await socket.emit(
                  "transport-produce",
                  {
                    // transportId: producerTransport.id,
                    kind: parameters.kind,
                    rtpParameters: parameters.rtpParameters,
                    appData: parameters.appData,
                  },
                  ({ id, producerExist }: any) => {
                    console.log(id);
                    callback(id);

                    if (producerExist) getProducers();
                  }
                );
              } catch (error) {
                errback(error);
              }
            }
          );

          connectSendTransport();
        }
      );
    };

    const signalNewConsumerTransport = (remoteProducerId: any) => {
      socket.emit(
        "createWebRtcTransport",
        { consumer: true },
        ({ params }: any) => {
          if (params.error) {
            console.log(params.error);
            return;
          }
          console.log("signalNewConsumerTransport params ", params);

          let consumerTransport;
          try {
            consumerTransport = device.createRecvTransport(params);
            console.log("createRecvTransport");
          } catch (error) {
            console.log(error);
            return;
          }

          consumerTransport.on(
            "connect",
            async ({ dtlsParameters }: any, callback: any, errback: any) => {
              try {
                await socket.emit("transport-recv-connect", {
                  // transportId: producerTransport.id,
                  dtlsParameters,
                  serverConsumerTransportId: params.id,
                });

                callback();
              } catch (error) {
                errback(error);
              }
            }
          );

          connectRecvTransport(consumerTransport, remoteProducerId, params.id);
        }
      );
    };
    const connectSendTransport = async () => {
      console.log(videoParams);
      videoProducer = await producerTransport.produce(videoParams);
      audioProducer = await producerTransport.produce(audioParams);
      videoProducer.on("trackended", () => {
        console.log("video track ended");
      });

      videoProducer.on("transportclose", () => {
        console.log("video transport close");
      });

      audioProducer.on("trackended", () => {
        console.log("audio track ended");
      });

      audioProducer.on("transportclose", () => {
        console.log("audio transport close");
      });
    };

    const connectRecvTransport = async (
      consumerTransport: any,
      remoteProducerId: any,
      serverConsumerTransportId: any
    ) => {
      console.log("connectRecvTransport");
      isHosting.value = false;
      hasProducer.value = true;
      await socket.emit(
        "consume",
        {
          rtpCapabilities: device.rtpCapabilities,
          remoteProducerId,
          serverConsumerTransportId,
        },
        async ({ params }: any) => {
          if (params.error) {
            console.log("cannot consume", params.error);
            return;
          }
          console.log(params);
          const consumer = await consumerTransport.consume({
            id: params.id,
            producerId: params.producerId,
            kind: params.kind,
            rtpParameters: params.rtpParameters,
          });

          consumerTransports = [
            ...consumerTransports,
            {
              consumerTransport,
              serverConsumerTransportId: params.id,
              producerId: remoteProducerId,
              consumer,
            },
          ];
          console.log("consumer", consumer);
          const { track } = consumer;

          console.log("recv track", consumer);
          // track.addTrack(audioTrack)
          const newEle = document.createElement("div");
          newEle.setAttribute("id", `c-${remoteProducerId}`);
          let c: any;

          if (params.kind === "audio") {
            // newEle.innerHTML = '<audio id="' + remoteProducerId + '" autoplay></audio>'
            c = document.getElementById("recvAudio");
          } else {
            // 'recvVideo'

            c = document.getElementById("recvVideo");
            // c.srcObject = new MediaStream([track])
            // newEle.setAttribute('class', 'remoteVideo')
            // newEle.innerHTML = '<video id="' + remoteProducerId + '" autoplay class="video" ></video>'
          }

          // document.getElementById('mediaContainer')!.appendChild(newEle)

          // c = document.getElementById(remoteProducerId)
          c.srcObject = new MediaStream([track]);

          socket.emit("consumer-resume", {
            serverConsumerId: params.serverConsumerId,
          });
        }
      );
    };

    socket.on("producer-closed", ({ remoteProducerId }: any) => {
      const producerToClose = consumerTransports.find(
        (transportData) => transportData.producerId === remoteProducerId
      );
      producerToClose.consumerTransport.close();
      producerToClose.consumer.close();

      consumerTransports = consumerTransports.filter(
        (transportData) => transportData.producerId !== remoteProducerId
      );

      // TODO LIVE STREAM IS OVER
      console.log("Stream is over");
    });

    onMounted(() => {
      joinRoom();
    });
    return {
      isHosting,
      socket,
      users,
      nickname,
      hasProducer,
      getLocalStream,
      getRtpCapabilities,
      createDevice,
      createSendTransport,
      connectSendTransport,
      connectRecvTransport,
      goLive,
      goConsume,
      joinRoom,
    };
  },
  components: {HanButtonVue},
});
